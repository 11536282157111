import DeleteButton from './components/DeleteButton.vue'
import PublicShareButton from './components/PublicShareButton.vue'
import SimpleModal from './components/SimpleModal.vue'
import MobileNav from './components/layout/MobileNav.vue'
import ProfileDropdown from './components/layout/ProfileDropdown.vue'
import HelpDropdown from './components/layout/HelpDropdown.vue'
import ChangePortalSelect from './components/ChangePortalSelect.vue'
import HintedInput from './components/HintedInput.vue'
import FileUploadSingle from './components/FileUploadSingle.vue'
import FileUploadMultiple from './components/FileUploadMultiple.vue'
import MediaCardSimple from './components/MediaCardSimple.vue'
import CandidateCard from './components/CandidateCard.vue'
import ButtonPrimary from './components/ButtonPrimary.vue'
import ButtonSecondary from './components/ButtonSecondary.vue'
import LinkPrimary from './components/LinkPrimary.vue'
import LinkSecondary from './components/LinkSecondary.vue'
import FlashMessage from './components/FlashMessage.vue'
import SelectSingle from './components/SelectSingle.vue'
import SelectMultiple from './components/SelectMultiple.vue'
import ClientHiringTeamSelector from './components/ClientHiringTeamSelector.vue'
import {ArchiveBoxXMarkIcon, ShareIcon} from '@heroicons/vue/24/outline'
import VideoEditorInterface from './components/VideoEditorInterface.vue'
import CustomVideoList from './components/CustomVideoList.vue'
import RichTextEditor from './components/RichTextEditor.vue'
import NameFormInputs from './components/NameFormInputs.vue'
import Onboarding from './components/onboarding/Onboarding.vue'
import HeadshotEditor from './components/HeadshotEditor.vue'
import VideoRecorder from './components/VideoRecording/VideoRecorder.vue'
import MessageThread from './components/MessageThread/MessageThread.vue'
import OnlineUsers from './components/OnlineUsers.vue'
import LiveNotifications from './components/LiveNotifications.vue'
import CandidateStats from './components/Stats/CandidateStats.vue'
import ButtonSubmit from './components/ButtonSubmit.vue'
import SearchFilters from './components/SearchFilters.vue'
import CandidateFilters from './components/CandidateFilters.vue'
import PositionManagementCandidate from './components/PositionManagementCandidate.vue'
import PositionManagementSearch from './components/PositionManagementSearch.vue'


import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faFilePdf,
  faFileVideo,
  faHouseChimney,
  faUserTie,
  faUser,
  faUsers,
  faBuildingMagnifyingGlass,
  faUserGroup,
  faPrintMagnifyingGlass,
  faCameraMovie,
  faEnvelope,
  faExclamationTriangle,
  faBuilding,
} from '@fortawesome/pro-light-svg-icons'

library.add(
  faFilePdf,
  faFileVideo,
  faHouseChimney,
  faUserTie,
  faUser,
  faUsers,
  faBuildingMagnifyingGlass,
  faUserGroup,
  faPrintMagnifyingGlass,
  faCameraMovie,
  faEnvelope,
  faExclamationTriangle,
  faBuilding
)


import 'shepherd.js/dist/css/shepherd.css'

import {createApp} from 'vue'
import { createStore } from 'vuex'
import storeData from "./store/index"

import * as Sentry from "@sentry/vue";

if(import.meta.env.VITE_SENTRY_DSN_PUBLIC){
  Sentry.init({
    environment: import.meta.env.VITE_APP_ENV || 'local',
    dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [/.*localhost/, /.*thiswayupcareer.net/ ],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { faExclamation } from '@fortawesome/pro-regular-svg-icons'


if(import.meta.env.VITE_PUSHER_APP_KEY){
  window.Pusher = Pusher;

  window.Echo = new Echo({
      broadcaster: 'pusher',
      key: import.meta.env.VITE_PUSHER_APP_KEY,
      cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
      forceTLS: true,
  });
}

const store = createStore(storeData);

createApp({
  methods:{
    toggleNav(){
      this.$store.commit('toggleNav')
    },
    toggleProfile(){
      this.$store.commit('toggleProfile')
    },
  },
  components: {
    FontAwesomeIcon,
    DeleteButton,
    PublicShareButton,
    SimpleModal,
    MobileNav,
    ProfileDropdown,
    HelpDropdown,
    ChangePortalSelect,
    HintedInput,
    FileUploadSingle,
    FileUploadMultiple,
    MediaCardSimple,
    CandidateCard,
    ButtonPrimary,
    ButtonSecondary,
    ButtonSubmit,
    LinkPrimary,
    LinkSecondary,
    FlashMessage,
    SelectSingle,
    SelectMultiple,
    ClientHiringTeamSelector,
    VideoEditorInterface,
    CustomVideoList,
    ArchiveBoxXMarkIcon,
    ShareIcon,
    RichTextEditor,
    NameFormInputs,
    Onboarding,
    HeadshotEditor,
    VideoRecorder,
    MessageThread,
    OnlineUsers,
    LiveNotifications,
    CandidateStats,
    SearchFilters,
    CandidateFilters,
    PositionManagementCandidate,
    PositionManagementSearch,
  }
})
.use(store)
.mount('#app');
